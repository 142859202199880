<script>
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import List from "./pages/list";
import createOrEdit from "./pages/createOrEdit";
import { mapGetters, mapState } from "vuex";
export default {
  name: "ExportsManageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    createOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  methods: {
    initContent() {
      return {
        file_name: "",
        data_store_id: "",
        created_at: "",
        export_status: ""
        // account_mappings: [{ scope: "ac", accounts: [], service_providers: [] }]
      };
    }

    // refreshScopeTemplate() {
    //   if (
    //     this.selectedAccountId === "all" &&
    //     this.selectedServiceProviderId === "all"
    //   ) {
    //     this.forceShowNotScoped = false;
    //   } else {
    //     this.forceScopeName = "Service Provider";
    //     this.forceShowNotScoped = false;
    //   }
    // }
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapGetters("app", {
      selectedServiceProviderAccountCombination:
        "selectedServiceProviderAccountCombination"
    })
  },

  mounted() {
    this.contentType = "Exports";
    this.primaryKey = "data_store_export_id";
    this.scope = "ac";
  }

  // watch: {
  //   selectedServiceProviderAccountCombination: {
  //     immediate: true,
  //     async handler() {
  //       await this.refreshScopeTemplate();
  //     }
  //   }
  // }
};
</script>

<style scoped></style>
