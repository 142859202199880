<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="formAction === 'select'">
      <div class="titles">File Name</div>
      <div class="details">{{ contentForm.file_name }}</div>
      <div class="titles">File Size</div>
      <div class="details">{{ contentForm.file_size }} MB</div>
      <div class="titles">Row Count</div>
      <div class="details">{{ contentForm.row_count }}</div>
      <div class="titles">Query Condition</div>
      <div class="details">{{ contentForm.query_condition }}</div>
      <!--      TODO: Add other required info-->

      <div style="display: flex; padding-top: 20px">
        <el-dropdown
          @command="handleAction"
          class="allAcActionDropDown"
          v-if="commandsToShow && commandsToShow.length != 0"
        >
          <el-button type="primary">
            Actions<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(command, index) in commandsToShow"
              :key="index"
              :command="command.command"
              :disabled="
                command.command === 'delete' ? !canWrite() : !canRead()
              "
            >
              <div
                style="display: flex; justify-content: flex-start; align-items: center"
              >
                <img
                  :src="getIconSVG(command.icon)"
                  alt
                  height="18px"
                  width="18px"
                  style="margin-right: 10px"
                />
                <span>{{ command.label }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  methods: {
    ...mapActions("exports", {
      deleteContentMethod: "deleteDataExport"
    }),

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },
    handleAction(action) {
      if (action === "delete") {
        this.handleDelete(this.contentForm);
      } else if (action === "download") {
        const link = document.createElement("a");
        link.href = this.contentForm.file_path;
        link.download = this.contentForm.file_name;
        link.click();
      }
    }
  },

  computed: {
    commandsToShow() {
      return _.filter(this.commands, command => {
        if (_.isEmpty(command.rules)) {
          return true;
        }
        return _.every(command.rules, (rule, key) => {
          return rule.includes(this.contentForm[key].toLowerCase());
        });
      });
    }
  },

  data() {
    return {
      commands: [
        {
          command: "download",
          rules: {
            export_status: ["completed"]
          },
          icon: "icon-download.svg",
          selectAction: false,
          label: "Download Exported Data",
          isDisabled: this.canRead
        },
        {
          command: "delete",
          rules: {
            export_status: ["completed", "failed"]
          },
          icon: "icon-delete.svg",
          selectAction: false,
          label: "Delete Exported Data",
          isDisabled: this.canRead
        }
      ],
      validateWrite: false // TODO: remove after adding permissions
    };
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;
  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

.editBtn {
  margin-top: 10px;
  height: 44px;
  background: $content-theme-color;
  border: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  &:hover {
    color: white;
    background: $content-theme-hover-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
