<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      title="Exports"
      style="padding: 20px 30px; width: calc(100% - 335px)"
      class="absolute"
    />
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="dataExportsTable"
            class="list-table"
            :data="exports"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-loading="loading"
          >
            <el-table-column label="File Name" prop="file_name" />
            <el-table-column label="Data Store ID" prop="data_store_id" />
            <el-table-column label="Status" prop="export_status" />
            <el-table-column label="Date Requested">
              <template slot-scope="scope">
                {{ getFormattedDate(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column label="Expires On">
              <template slot-scope="scope">
                {{ addThirtyDaysToDate(scope.row.updated_at) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "ExportsManageList",
  mixins: [BaseContent],
  components: { PageHeader, PaginationToolbar },

  methods: {
    ...mapActions("exports", {
      contentAPI: "getExportDataForUser"
    }),

    handleClear() {
      this.$refs.dataExportsTable &&
        this.$refs.dataExportsTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      this.handleSingleClick(row, column, event);
    },
    getFormattedDate(date) {
      return this.formattedDateTime(date, this.displayDateFormat.toUpperCase());
    },
    addThirtyDaysToDate(date) {
      let newDate = moment(date).add(30, "days");
      return this.formattedDateTime(
        newDate,
        this.displayDateFormat.toUpperCase()
      );
    }
  },

  computed: {
    ...mapState("exports", {
      exports: state => state.exports,
      loading: state => state.loading
    }),

    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime",
      displayDateFormat: "displayDateFormat"
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
